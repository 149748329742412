<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">树洞列表</span>
      <br><br>
      <el-input size="small" placeholder="搜索树洞号" v-model="searchId" class="input-with-select" 
                style="width:100%" @change="gotoPostInfoBySearch">
        <el-select v-model="searchIdType" slot="prepend" placeholder="选择编号类型" style="width: 130px">
          <el-option label="本校编号" value="1" style="color:black"></el-option>
          <el-option label="UNI编号" value="2" style="color:black"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="gotoPostInfoBySearch"></el-button>
      </el-input>
      <br><br>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
      <el-table :data="postListData" style="font-size: 14px; width: 100%; color: black" @row-dblclick="gotoPostInfoByRow">
        <el-table-column prop="post_id" label="本校编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="uni_post_id" label="UNI编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_topic" label="话题" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_msg" label="内容" min-width="28%">
        </el-table-column>
        <el-table-column prop="user_itsc" label="洞主" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.user_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.user_itsc, scope.row.user_school_label)">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_view" label="浏览" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_comment_num" label="评论" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_follower_num" label="围观" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_create_time" label="时间" min-width="9%">
        </el-table-column>
        <el-table-column prop="post_public" label="状态" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_opt" label="操作" min-width="18%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="warning" @click="handleShadowban(scope.row)" plain>屏蔽</el-button>
            <el-button size="medium" type="success" @click="handlePublic(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'PostForm',
    created() {
      this.page = 1,
      this.getPostList()
    },
    data() {
      return {
        searchId: '',
        searchIdType: '',
        postListData: []
      }
    },
    methods:{
      getPostList(){
        const loading = this.$loading();
        request('/post/post_list',{page: this.page-1}).then(
          (res) => {this.postListData = res.post_list; loading.close()})
      },
      gotoPostInfoBySearch(){
        if (this.searchIdType == ''){
          this.$message('请选择编号类型')
        }
        else if (this.searchId == ''){
          this.$message('请输入树洞编号')
        }
        if (this.searchIdType == 1){
          window.open('/post/'+ this.searchId)
        }
        else if (this.searchIdType == 2){
          window.open('/post/uni' + this.searchId)
        }
      },
      gotoPostInfoByRow(row){
        window.open('/post/uni'+row.uni_post_id);
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      handleCurrentChange(pg){
        this.page = pg;
        this.getPostList();
      },
      handlePrevClick(){
        this.page = this.page - 1;
        this.getPostList();
      },
      handleNextClick(){
        this.page = this.page + 1;
        this.getPostList();
      },
      handleDelete(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getPostList();})
      },
      handleShadowban(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'shadowban'}).then(
          (res) => {this.$message(res.msg); this.getPostList();})
      },
      handlePublic(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getPostList();})
      }
    }
  }
</script>