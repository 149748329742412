<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">私信记录</span>
      <br><br>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
      <el-table :data="pmListData" style="font-size: 14px; width: 100%; color:black" @row-dblclick="gotoChatInfo">
        <el-table-column prop="pm_id" label="私信编号" min-width="6%">
        </el-table-column>
        <el-table-column prop="chat_id" label="私聊编号" min-width="6%">
        </el-table-column>
        <el-table-column prop="sender_itsc" label="发起者" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" @click="gotoUserInfoByTag(scope.row.sender_itsc, scope.row.sender_school_label)">{{ scope.row.sender_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sender_school_label" label="发起者学校" min-width="9%">
        </el-table-column>
        <el-table-column prop="receiver_itsc" label="接收者" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" @click="gotoUserInfoByTag(scope.row.receiver_itsc,scope.row.receiver_school_label)">{{ scope.row.receiver_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="receiver_school_label" label="接收者学校" min-width="9%">
        </el-table-column>
        <el-table-column prop="pm_msg" label="内容" min-width="40%">
        </el-table-column>
        <el-table-column prop="pm_create_time" label="时间" min-width="12%">
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'PmForm',
    created() {
      this.page = 1
      this.getPmList()
    },
    data() {
      return {
        pmListData: []
      }
    },
    methods: {
      getPmList(){
        const loading = this.$loading();
        request('/chat/chat_pmlist', {page: this.page-1}).then((res) => {this.pmListData = res.chat_pmlist; loading.close()})
      },
      gotoChatInfo(row){
        window.open('/chat/'+row.chat_id)
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      handleCurrentChange(pg){
        this.page = pg;
        this.getPmList();
      },
      handlePrevClick(){
        this.page = this.page - 1;
        this.getPmList();
      },
      handleNextClick(){
        this.page = this.page + 1;
        this.getPmList();
      }
    }
  }
</script>