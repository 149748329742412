<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">文章列表</span>
      <br><br>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
      <el-table :data="articleListData" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="article_id" label="文章编号" min-width="6%">
        </el-table-column>
        <el-table-column prop="article_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="article_author" label="社团" min-width="9%">
        </el-table-column>
        <el-table-column prop="article_title" label="标题" min-width="34%">
        </el-table-column>
        <el-table-column prop="article_link" label="链接" min-width="16%">
        </el-table-column>
        <el-table-column prop="article_create_time" label="时间" min-width="10%">
        </el-table-column>
        <el-table-column prop="article_public" label="状态" min-width="4%">
        </el-table-column>
        <el-table-column prop="article_opt" label="操作" min-width="14%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="success" @click="handlePublic(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'ArticleForm',
    created() {
      this.page = 1
      this.getArticleList()
    },
    data() {
      return {
        articleListData: []
      }
    },
    methods: {
      getArticleList(){
        const loading = this.$loading();
        request('/article/article_list',{page: this.page-1}).then((res) => {this.articleListData = res.article_list; loading.close()})
      },
      handleCurrentChange(pg){
        this.page = pg;
        this.getArticleList();
      },
      handlePrevClick(){
        this.page = this.page - 1;
        this.getArticleList();
      },
      handleNextClick(){
        this.page = this.page + 1;
        this.getArticleList();
      },
      handleDelete(row){
        request('/article/article_publicity',{article_id: row.article_id, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getArticleList();})
      },
      handlePublic(row){
        request('/article/article_publicity',{article_id: row.article_id, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getArticleList();})
      }
    }
  }
</script>