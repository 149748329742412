<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">私聊列表</span>
      <br><br>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
      <el-table :data="chatListData" style="font-size: 14px; width: 90%; color:black" @row-dblclick="gotoChatInfo">
        <el-table-column prop="chat_id" label="私聊编号" min-width="8%">
        </el-table-column>
        <el-table-column prop="sender_itsc" label="发起者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.sender_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.sender_itsc, scope.row.sender_school_label)">{{ scope.row.sender_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sender_serial" label="发起者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="sender_school_label" label="发起者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="receiver_itsc" label="接收者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.receiver_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.receiver_itsc, scope.row.receiver_school_label)">{{ scope.row.receiver_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="receiver_serial" label="接收者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="receiver_school_label" label="接收者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="chat_pm_count" label="私信数量" min-width="10%">
        </el-table-column>
        <el-table-column prop="chat_create_time" label="发起时间" min-width="12%">
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="30" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'ChatForm',
    created() {
      this.page = 1
      this.getChatList()
    },
    data() {
      return {
        chatListData: []
      }
    },
    methods: {
      getChatList(){
        const loading = this.$loading();
        request('/chat/chat_list',{page: this.page-1}).then((res) => {this.chatListData = res.chat_list; loading.close()})
      },
      gotoChatInfo(row){
        window.open('/chat/'+row.chat_id)
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      handleCurrentChange(pg){
        this.page = pg;
        this.getChatList();
      },
      handlePrevClick(){
        this.page = this.page - 1;
        this.getChatList();
      },
      handleNextClick(){
        this.page = this.page + 1;
        this.getChatList();
      }
    }
  }
</script>