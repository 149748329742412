import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import PostForm from '../components/PostForm.vue'
import PostInfo from '../components/PostInfo.vue'
import UserForm from '../components/UserForm.vue'
import ArticleForm from '../components/ArticleForm.vue'
import BannerForm from '../components/BannerForm.vue'
import ChatForm from '../components/ChatForm.vue'
import ChatInfo from '../components/ChatInfo.vue'
import PmForm from '../components/PmForm.vue'
//import SearchPanel from '../components/SearchPanel.vue'
//import OptPanel from '../components/OptPanel.vue'

Vue.use(Router)

export default new Router({
  mode: `history`,
  routes: [
    {
      path: '/',
      redirect: 'post'
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta:{
        keepalive: false
      }
    },
    {
      path: '/post',
      name: 'post',
      component: PostForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/post/:id?',
      name: 'postInfo',
      component: PostInfo,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/user',
      name: 'user',
      component: UserForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/user/:id?',
      name: 'userInfo',
      component: UserForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/article',
      name: 'articleForm',
      component: ArticleForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/banner',
      name: 'bannerForm',
      component: BannerForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/chat',
      name: 'chat',
      component: ChatForm,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/chat/:id?',
      name: 'chatInfo',
      component: ChatInfo,
      meta:{
        keepalive: true
      }
    },
    {
      path: '/pm',
      name: 'pm',
      component: PmForm,
      meta:{
        keepalive: true
      }
    }
    // {
    //   path: '/opt',
    //   name: 'opt',
    //   component: OptPanel,
    //   meta:{
    //     keepalive: true
    //   }
    // }
    // {
    //   path: '/search',
    //   name: 'search',
    //   component: SearchPanel
    // }
  ]
})
