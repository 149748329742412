<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">用户信息</span>
      <br><br>
      <el-input size="small" placeholder="搜索用户ITSC" v-model="searchItsc" class="input-with-select" 
                style="width:100%" @change="gotoUserInfoBySearch">
        <el-select v-model="searchSchoolLabel" slot="prepend" placeholder="选择用户学校" style="width: 130px">
          <el-option label="HKU" value="hku" style="color:black"></el-option>
          <el-option label="UST" value="ust" style="color:black"></el-option>
          <el-option label="CUHK" value="cuhk" style="color:black"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="gotoUserInfoBySearch"></el-button>
      </el-input>
      <br><br>
      <el-table :data="userInfoData" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="user_itsc" label="ITSC" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="user_type" label="类型" min-width="4%">
        </el-table-column>
        <el-table-column prop="user_serial" label="昵称" min-width="8%">
        </el-table-column>
        <el-table-column prop="user_silence_until" label="禁言至" min-width="12%">
        </el-table-column>
        <el-table-column prop="user_ban_until" label="冻结至" min-width="12%">
        </el-table-column>
        <el-table-column prop="user_ban_times" label="冻结次数" min-width="7%">
        </el-table-column>
        <el-table-column prop="user_status" label="状态" min-width="4%">
        </el-table-column>
        <el-table-column prop="user_opt" label="操作" min-width="39%">
          <el-popover placement="bottom" width="240" v-model="visible" style="margin-right: 2%">
            <div style="align-items: center; text-align: center; margin: 0">
              <el-button size="medium" type="warning" @click="handleSilence1" plain>1天</el-button>
              <el-button size="medium" type="warning" @click="handleSilence2" plain>7天</el-button>
              <el-button size="medium" type="warning" @click="handleSilence3" plain>30天</el-button>
            </div>
            <el-button slot="reference" size="medium" type="warning" plain>禁言一次</el-button>
          </el-popover>
          <el-button size="medium" type="warning" @click="handleFreeze" plain>冻结一次</el-button>
          <el-button size="medium" type="danger" @click="handleBan" plain>永久冻结</el-button>
          <el-button size="medium" type="success" @click="handleUnSilence" plain>解除禁言</el-button>
          <el-button size="medium" type="success" @click="handleUnFreeze" plain>解除冻结</el-button>
        </el-table-column>
      </el-table>
      <br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">发布的树洞</span>
      <el-table :data="userPostData" style="font-size: 14px; width: 100%; color: black" @row-dblclick="gotoPostInfoByRow">
        <el-table-column prop="post_id" label="本校编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="uni_post_id" label="UNI编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_topic" label="话题" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_msg" label="内容" min-width="28%">
        </el-table-column>
        <el-table-column prop="user_itsc" label="洞主" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.user_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.user_itsc, scope.row.user_school_label)">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_view" label="浏览" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_comment_num" label="评论" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_follower_num" label="围观" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_create_time" label="时间" min-width="9%">
        </el-table-column>
        <el-table-column prop="post_public" label="状态" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_opt" label="操作" min-width="18%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDeletePost(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="warning" @click="handleShadowbanPost(scope.row)" plain>屏蔽</el-button>
            <el-button size="medium" type="success" @click="handlePublicPost(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChangePost" :current-page.sync="postPage"
                     @prev-click="handlePrevClickPost" @next-click="handleNextClickPost"
                     small :page-size="10" layout="prev, next, jumper">
      </el-pagination>
      <br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">发表的评论</span>
      <el-table :data="userCommentData" style="font-size: 14px; width: 100%; color:black" @row-dblclick="gotoPostInfoByRow">
        <el-table-column prop="comment_id" label="评论编号" min-width="7%">
        </el-table-column>
        <el-table-column prop="comment_order" label="楼层" min-width="5%">
        </el-table-column>
        <el-table-column prop="user_itsc" label="层主" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.user_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.user_itsc, scope.row.user_school_label)">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="comment_msg" label="内容" min-width="36%">
        </el-table-column>
        <el-table-column prop="comment_create_time" label="时间" min-width="12%">
        </el-table-column>
        <el-table-column prop="comment_public" label="状态" min-width="5%">
        </el-table-column>
        <el-table-column prop="comment_opt" label="操作" min-width="21%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDeleteComment(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="warning" @click="handleShadowbanComment(scope.row)" plain>屏蔽</el-button>
            <el-button size="medium" type="success" @click="handlePublicComment(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChangeComment" :current-page.sync="commentPage"
                     @prev-click="handlePrevClickComment" @next-click="handleNextClickComment"
                     small :page-size="10" layout="prev, next, jumper">
      </el-pagination>
      <br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">参与的私聊</span>
      <el-table :data="userChatData" style="font-size: 14px; width: 90%; color:black" @row-dblclick="gotoChatInfo">
        <el-table-column prop="chat_id" label="私聊编号" min-width="8%">
        </el-table-column>
        <el-table-column prop="sender_itsc" label="发起者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.sender_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.sender_itsc, scope.row.sender_school_label)">{{ scope.row.sender_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sender_serial" label="发起者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="sender_school_label" label="发起者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="receiver_itsc" label="接收者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.receiver_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.receiver_itsc, scope.row.receiver_school_label)">{{ scope.row.receiver_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="receiver_serial" label="接收者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="receiver_school_label" label="接收者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="chat_pm_count" label="私信数量" min-width="10%">
        </el-table-column>
        <el-table-column prop="chat_create_time" label="发起时间" min-width="12%">
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChangeChat" :current-page.sync="chatPage"
                     @prev-click="handlePrevClickChat" @next-click="handleNextClickChat"
                     small :page-size="10" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'UserForm',
    created() {
      this.updatePage()
    },
    watch: {
      "$route": "updatePage"
    },
    data() {
      return {
        searchItsc: '',
        searchSchoolLabel: '',
        userInfoData: [],
        userPostData: [],
        userCommentData: [],
        userChatData: []
      }
    },
    methods: {
      updatePage(){
        this.userItsc = this.$route.path.split('/').pop().split('_').pop()
        this.userSchoolLabel = this.$route.path.substring(this.$route.path.lastIndexOf('/')+1,this.$route.path.lastIndexOf('_'))
        this.postPage = 1
        this.commentPage = 1
        this.chatPage = 1
        this.searchItsc = ''
        this.searchSchoolLabel = ''
        this.userInfoData = []
        this.userPostData = []
        this.userCommentData = []
        this.userChatData = []
        this.getUserInfo()
        this.getUserPost()
        this.getUserComment()
        this.getUserChat()
      },
      getUserInfo(){
        const loading = this.$loading();
        request('/user/user_info',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel}).then(
          (res) => {this.userInfoData = res.user_info; loading.close(); if (res.code != 200 && res.code != 801) this.$message(res.msg);})
      },
      getUserPost(){
        const loading = this.$loading();
        request('/user/user_post',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, page: this.postPage-1}).then((res) => {this.userPostData = res.user_post; loading.close()})
      },
      getUserComment(){
        const loading = this.$loading();
        request('/user/user_comment',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, page: this.commentPage-1}).then((res) => {this.userCommentData = res.user_comment; loading.close()})
      },
      getUserChat(){
        const loading = this.$loading();
        request('/user/user_chat',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, page: this.chatPage-1}).then((res) => {this.userChatData = res.user_chat; loading.close()})
      },
      gotoUserInfoBySearch(){
        if (this.searchSchoolLabel == '') this.$message("请选择用户学校")
        else if (this.searchItsc == '') this.$message("请输入用户ITSC")
        else if (this.searchItsc != this.userItsc || this.searchSchoolLabel != this.userSchoolLabel) window.open('/user/'+this.searchSchoolLabel+'_'+this.searchItsc)
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        if (userItsc != this.userItsc || userSchoolLabel != this.userSchoolLabel)
          window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      gotoPostInfoByRow(row){
        window.open('/post/uni'+row.uni_post_id);
      },
      gotoChatInfo(row){
        window.open('/chat/'+row.chat_id);
      },
      handleCurrentChangePost(pg){
        this.postPage = pg;
        this.getUserPost();
      },
      handlePrevClickPost(){
        this.postPage = this.postPage - 1;
        this.getUserPost();
      },
      handleNextClickPost(){
        this.postPage = this.postPage + 1;
        this.getUserPost();
      },
      handleCurrentChangeComment(pg){
        this.commentpage = pg;
        this.getUserComment();
      },
      handlePrevClickComment(){
        this.commentPage = this.commentPage - 1;
        this.getUserComment();
      },
      handleNextClickComment(){
        this.commentPage = this.commentPage + 1;
        this.getUserComment();
      },
      handleCurrentChangeChat(pg){
        this.chatpage = pg;
        this.getUserChat();
      },
      handlePrevClickChat(){
        this.chatPage = this.chatPage - 1;
        this.getUserChat();
      },
      handleNextClickChat(){
        this.chatPage = this.chatPage + 1;
        this.getUserChat();
      },
      handleSilence1(){
        request('/user/user_silent',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 1, silent_period: 1}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleSilence2(){
        request('/user/user_silent',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 1, silent_period: 7}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleSilence3(){
        request('/user/user_silent',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 1, silent_period: 30}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleFreeze(){
        request('/user/user_freeze',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 1}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleBan(){
        request('/user/user_ban',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 1}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleUnSilence(){
        request('/user/user_silent',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 2, silent_period: -1}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleUnFreeze(){
        request('/user/user_freeze',{user_itsc: this.userItsc, user_school_label: this.userSchoolLabel, opt: 2}).then(
          (res) => {this.$message(res.msg); this.getUserInfo()})
      },
      handleDeletePost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getUserPost();})
      },
      handleShadowbanPost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'shadowban'}).then(
          (res) => {this.$message(res.msg); this.getUserPost();})
      },
      handlePublicPost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getUserPost();})
      },
      handleDeleteComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: row.uni_post_id, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getUserComment();})
      },
      handleShadowbanComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: row.uni_post_id, opt: 'shadowban'}).then(
          (res) => {this.$message(res.msg); this.getUserComment();})
      },
      handlePublicComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: row.uni_post_id, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getUserComment();})
      }
    }
  }
</script>