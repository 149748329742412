<template>
  <div id="app">
  <el-container style="height: 100%; width: 100%">
    <el-aside width="63px" v-if="$route.meta.keepalive">
      <el-menu router :default-active="this.$route.path">
        <el-tooltip class="item" effect="dark" content="树洞" placement="right">
          <el-menu-item index="/post"><i class="el-icon-receiving" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="用户" placement="right">
          <el-menu-item index="/user"><i class="el-icon-user" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="文章" placement="right">
          <el-menu-item index="/article"><i class="el-icon-document" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="宣传" placement="right">
          <el-menu-item index="/banner"><i class="el-icon-collection-tag" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="私聊" placement="right">
          <el-menu-item index="/chat"><i class="el-icon-message" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="私信" placement="right">
          <el-menu-item index="/pm"><i class="el-icon-chat-dot-round" style="font-size: medium"></i></el-menu-item>
        </el-tooltip>
        <!-- <el-tooltip class="item" effect="dark" content="搜索" placement="right">
          <el-menu-item index="/search"><i class="el-icon-search" style="font-size: medium"></i></el-menu-item>
        </el-tooltip> -->
        <!-- <el-tooltip class="item" effect="dark" content="操作" placement="right">
          <el-menu-item index="/opt"><i class="el-icon-magic-stick" style="font-size: medium"></i></el-menu-item>
        </el-tooltip> -->
      </el-menu>
    </el-aside>
    <router-view></router-view>
  </el-container>
  </div>
</template>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    /* -moz-osx-font-smoothing: grayscale; */
  }

  .el-aside {
    background-color: #fff;
    color: #000;
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .websit{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>

<script>
  export default {
    name: 'app'
  }
</script>