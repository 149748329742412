<template>
  <el-container>
    <el-main>
      <div style="text-align: center; margin-top: 15%;">
        <div style="text-align: center">
          <span style="font-size: 30px;">UNI 后台管理</span>
        </div>
        <br><br>
        <div style="text-align: center">
        <el-input placeholder="输入用户名" v-model="name" style="width: 300px; margin-bottom: 10px"></el-input>
        </div>
        <div style="text-align: center">
          <el-input placeholder="输入密码" v-model="pwd" style="width: 300px; margin-bottom: 10px" show-password></el-input>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="login" style="width: 300px; margin-bottom: 10px">登录</el-button>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<style>
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: "Login",
    data(){
      return {
        name: '',
        pwd : ''
      }
    },
    methods:{
      login(){
        if (this.name == '') this.$message("请输入用户名");
        else if (this.pwd == '') this.$message("请输入密码");
        else{
          const loading = this.$loading();
          request('/login/login',{name: this.name, pwd: this.pwd},false).then((res) => {
            loading.close()
            this.$message(res.msg);
            if (res.code == 200){
              localStorage.setItem('token', res.token);
              //localStorage.setItem('admin_access', res.admin_access);
              this.$router.push('/post');
            }
          })
        }
      }
    }
  }
</script>