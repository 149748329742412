
export const upload = (file, user_school_label, type) => {
  // console.log(file);
  var Bucket = "boatonland-1307992092";
  var Region = "ap-beijing";
  var COS = require("./cos.js");
  var cos = new COS({
      ForcePathStyle: true,
      getAuthorization(options, callback) {
          var url =
              "https://upload.tripleuni.com/index.php?bucket=" +
              Bucket +
              "&region=" +
              Region;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
              try {
                  var data = JSON.parse(e.target.responseText);
                  var credentials = data.credentials;
              } catch (e) {
                  console.log(e)
               }
              if (!data || !credentials) {
                  return console.error(
                      "credentials invalid:\n" + JSON.stringify(data, null, 2)
                  );
              }
              callback({
                  TmpSecretId: credentials.tmpSecretId,
                  TmpSecretKey: credentials.tmpSecretKey,
                  SecurityToken: credentials.sessionToken,
                  StartTime: data.startTime,
                  ExpiredTime: data.expiredTime,
              });
          };
          xhr.send();
      },
  });

  return new Promise ((resolve, reject) => {
      cos.putObject(
          {
              Bucket: Bucket,
              Region: Region,
              Key: user_school_label + '/' + type + '/' + randomString() + getExt(file.name),
              StorageClass: "STANDARD",
              Body: file
          },
          function (err, data) {
              // console.log(err || data);
              if (data.Location) {
                  var location =
                      "https://i.boatonland.com/" + user_school_label + '/' + type + '/' +
                      data.Location.substr(data.Location.lastIndexOf("/") + 1);
                  resolve(location)
              }else{
                  reject()
              }
          }
      );
  })
  
}

const randomString = (e) => {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
  for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}

export const getExt = (filename) => {
  var idx = filename.lastIndexOf('.');
  return (idx < 1) ? "" : "." + filename.substr(idx + 1);
}