<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">宣传列表</span>
      <br><br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">添加</span>
      <br><br>
      <div>
        <span style="text-align: left; font-size: 14px; font-weight: bold; margin-right: 30px;">学校: </span>
        <el-radio v-model="bannerSchoolLabel" label="1" style="color:black">HKU</el-radio>
        <el-radio v-model="bannerSchoolLabel" label="2" style="color:black">UST</el-radio>
        <el-radio v-model="bannerSchoolLabel" label="3" style="color:black">CUHK</el-radio>
      </div>
      <br>
      <div>
        <span style="text-align: left; font-size: 14px; font-weight: bold; margin-right: 30px;">类型: </span>
        <el-radio v-model="bannerType" label="1" @input="handleSelectBannerType" style="color:black">文章</el-radio>
        <el-radio v-model="bannerType" label="2" @input="handleSelectBannerType" style="color:black">树洞</el-radio>
      </div>
      <br>
      <el-input v-if="bannerIsArticle" size="small" placeholder="输入文章链接" v-model="bannerArticleLink" style="width:100%; margin-bottom: 10px;"></el-input>
      <el-input v-if="!bannerIsArticle" size="small" placeholder="输入树洞编号" v-model="bannerPostId" class="input-with-select" style="width:100%; margin-bottom: 10px;">
        <el-select v-model="bannerPostIdType" slot="prepend" placeholder="选择编号类型" style="width: 130px">
          <el-option label="本校编号" value="1"></el-option>
          <el-option label="UNI编号" value="2"></el-option>
        </el-select>
      </el-input>
      <el-form style="color:black">
      <el-form-item label="上传图片" style="color:black">
        <el-upload class="upload-demo" action="#" accept=".png,.jpg,.jpeg" :limit="1" drag
          :http-request="uploadBannerImage" :show-file-list="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <el-image style="height: 100px" :src="bannerImageLink" :preview-src-list="[bannerImageLink]" v-if="bannerImageLink"></el-image>
      </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button size="medium" type="primary" @click="handleBannerInsert(1)" plain>设为Banner</el-button>
        <el-button size="medium" type="primary" @click="handleBannerInsert(2)" plain>设为树洞页广告</el-button>
        <el-button size="medium" type="primary" @click="handleBannerInsert(3)" plain>设为开屏广告</el-button>
        <el-button size="medium" type="primary" @click="handleBannerInsert(4)" plain>置顶树洞</el-button>
      </div>
      <br>
      <span v-if="bannerListDataHKU" style="text-align: left; font-size: 15px; font-weight: bold;">HKU</span>
      <el-table :data="bannerListDataHKU" v-if="bannerListDataHKU" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="meta_key" label="meta类型" min-width="6%">
        </el-table-column>
        <el-table-column prop="banner_type" label="链接类型" min-width="5%">
        </el-table-column>
        <el-table-column prop="banner_image" label="图片链接" min-width="36%">
        </el-table-column>
        <el-table-column prop="banner_link" label="文章链接/树洞编号" min-width="26%">
        </el-table-column>
        <el-table-column prop="meta_status" label="状态" min-width="3%">
        </el-table-column>
        <el-table-column prop="meta_create_time" label="时间" min-width="12%">
        </el-table-column>
        <el-table-column prop="meta_opt" label="操作" min-width="6%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <!-- <el-button size="medium" type="success" @click="handlePublic(scope.row)" plain>置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination @current-change="handleCurrentChangeHKU" :current-page.sync="hkuPage"
                     @prev-click="handlePrevClickHKU" @next-click="handleNextClickHKU"
                     small :page-size="5" layout="prev, next, jumper" v-if="bannerListDataHKU" >
      </el-pagination> -->
      <br>
      <span v-if="bannerListDataUST" style="text-align: left; font-size: 15px; font-weight: bold;">UST</span>
      <el-table :data="bannerListDataUST" v-if="bannerListDataUST" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="meta_key" label="meta类型" min-width="6%">
        </el-table-column>
        <el-table-column prop="banner_type" label="链接类型" min-width="5%">
        </el-table-column>
        <el-table-column prop="banner_image" label="图片链接" min-width="36%">
        </el-table-column>
        <el-table-column prop="banner_link" label="文章链接/树洞编号" min-width="26%">
        </el-table-column>
        <el-table-column prop="meta_status" label="状态" min-width="3%">
        </el-table-column>
        <el-table-column prop="meta_create_time" label="时间" min-width="12%">
        </el-table-column>
        <el-table-column prop="meta_opt" label="操作" min-width="6%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <!-- <el-button size="medium" type="success" @click="handlePublic(scope.row)" plain>置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination @current-change="handleCurrentChangeUST" :current-page.sync="ustPage"
                     @prev-click="handlePrevClickUST" @next-click="handleNextClickUST"
                     small :page-size="5" layout="prev, next, jumper" v-if="bannerListDataUST" >
      </el-pagination> -->
      <br>
      <span v-if="bannerListDataCUHK" style="text-align: left; font-size: 15px; font-weight: bold;">CUHK</span>
      <el-table :data="bannerListDataCUHK" v-if="bannerListDataCUHK" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="meta_key" label="meta类型" min-width="6%">
        </el-table-column>
        <el-table-column prop="banner_type" label="链接类型" min-width="5%">
        </el-table-column>
        <el-table-column prop="banner_image" label="图片链接" min-width="36%">
        </el-table-column>
        <el-table-column prop="banner_link" label="文章链接/树洞编号" min-width="26%">
        </el-table-column>
        <el-table-column prop="meta_status" label="状态" min-width="3%">
        </el-table-column>
        <el-table-column prop="meta_create_time" label="时间" min-width="12%">
        </el-table-column>
        <el-table-column prop="meta_opt" label="操作" min-width="6%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDelete(scope.row)" plain>删除</el-button>
            <!-- <el-button size="medium" type="success" @click="handlePublic(scope.row)" plain>置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination @current-change="handleCurrentChangeCUHK" :current-page.sync="cuhkPage"
                     @prev-click="handlePrevClickCUHK" @next-click="handleNextClickCUHK"
                     small :page-size="5" layout="prev, next, jumper" v-if="bannerListDataCUHK" >
      </el-pagination> -->
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  import { upload } from '../utils/upload.js'
  export default {
    name: 'ArticleForm',
    created() {
      // this.hkuPage = 1,
      // this.ustPage = 1,
      // this.cuhkPage = 1
      this.bannerSchoolLabel = '1',
      this.bannerType = '1',
      this.bannerIsArticle = true
      this.getBannerList()
    },
    data() {
      return {
        bannerListDataHKU: '',
        bannerListDataUST: '',
        bannerListDataCUHK: '',
        bannerSchoolLabel: '',
        bannerType: '',
        bannerImageLink: '',
        bannerArticleLink: '',
        bannerPostIdType: '',
        bannerPostId: ''
      }
    },
    methods: {
      getBannerList(){
        this.getBannerListHKU()
        this.getBannerListUST()
        this.getBannerListCUHK()
      },
      getBannerListHKU(){
        const loading = this.$loading();
        request('/banner/banner_list', {school_label: 'HKU', page: this.hkuPage-1}).then(
          (res) => {this.bannerListDataHKU = res.banner_list; loading.close()})
      },
      getBannerListUST(){
        const loading = this.$loading();
        request('/banner/banner_list', {school_label: 'UST', page: this.ustPage-1}).then(
          (res) => {this.bannerListDataUST = res.banner_list; loading.close()})
      },
      getBannerListCUHK(){
        const loading = this.$loading();
        request('/banner/banner_list', {school_label: 'CUHK', page: this.cuhkPage-1}).then(
          (res) => {this.bannerListDataCUHK = res.banner_list; loading.close()})
      },
      uploadBannerImage(e) {
        const loading = this.$loading();
        upload(e.file, this.bannerSchoolLabel, 'org')
          .then((image_link) => {
            this.bannerImageLink = image_link
            loading.close()
            return false
          })
      },
      handleCurrentChangeHKU(pg){
        this.hkuPage = pg;
        this.getBannerListHKU();
      },
      handlePrevClickHKU(){
        this.hkuPage = this.hkuPage - 1;
        this.getBannerListHKU();
      },
      handleNextClickHKU(){
        this.hkuPage = this.hkuPage + 1;
        this.getBannerListHKU();
      },
      handleCurrentChangeUST(pg){
        this.ustPage = pg;
        this.getBannerListUST();
      },
      handlePrevClickUST(){
        this.ustPage = this.ustPage - 1;
        this.getBannerListUST();
      },
      handleNextClickUST(){
        this.ustPage = this.ustPage + 1;
        this.getBannerListUST();
      },
      handleCurrentChangeCUHK(pg){
        this.cuhkPage = pg;
        this.getBannerListCUHK();
      },
      handlePrevClickCUHK(){
        this.cuhkPage = this.cuhkPage - 1;
        this.getBannerListCUHK();
      },
      handleNextClickCUHK(){
        this.cuhkPage = this.cuhkPage + 1;
        this.getBannerListCUHK();
      },
      handleBannerInsert(type){
        if (this.bannerIsArticle){
          if (type==4) this.$message("不支持置顶文章");
          else if (this.bannerArticleLink == '') this.$message("请输入文章链接");
          else if (this.bannerImageLink == '') this.$message("请上传图片");
          else{
            const loading = this.$loading();
            request('/banner/banner_insert',{type: type, banner_school_label: this.bannerSchoolLabel, banner_type: this.bannerType, 
                    banner_image: this.bannerImageLink, article_link: this.bannerArticleLink}).then(
              (res) => {this.$message(res.msg); this.getBannerList(); loading.close()})
          }
        }
        else{
          if (this.bannerPostIdType == '') this.$message("请选择编号类型");
          else if (this.bannerPostId == '') this.$message("请输入树洞编号");
          else if (this.bannerImageLink == '' && type != 4) this.$message("请上传图片");
          else{
            const loading = this.$loading();
            request('/banner/banner_insert',{type: type, banner_school_label: this.bannerSchoolLabel, banner_type: this.bannerType, 
                    banner_image: this.bannerImageLink, post_id_type: this.bannerPostIdType, post_id: this.bannerPostId}).then(
              (res) => {this.$message(res.msg); this.getBannerList(); loading.close()})
          }
        }
      },
      handleSelectBannerType(){
        if (this.bannerType == 1) this.bannerIsArticle = true
        else this.bannerIsArticle = false
      },
      handleDelete(row){
        request('/banner/banner_publicity',{meta_id: row.meta_id, opt: 'delete', school_label: row.meta_school_label}).then(
          (res) => {this.$message(res.msg); this.getBannerList();})
      },
      handlePublic(row){
        request('/banner/banner_publicity',{meta_id: row.meta_id, opt: 'public', school_label: row.meta_school_label}).then(
          (res) => {this.$message(res.msg); this.getBannerList();})
      }
    }
  }
</script>