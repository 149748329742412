<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">私聊详情</span>
      <br><br>
      <el-table :data="chatInfoData" style="font-size: 14px; width: 90%; color:black">
        <el-table-column prop="chat_id" label="私聊编号" min-width="8%">
        </el-table-column>
        <el-table-column prop="sender_itsc" label="发起者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" type="success" @click="gotoUserInfoByTag(scope.row.sender_itsc, scope.row.sender_school_label)">{{ scope.row.sender_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sender_serial" label="发起者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="sender_school_label" label="发起者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="receiver_itsc" label="接收者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" type="danger" @click="gotoUserInfoByTag(scope.row.receiver_itsc, scope.row.receiver_school_label)">{{ scope.row.receiver_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="receiver_serial" label="接收者名称" min-width="13%">
        </el-table-column>
        <el-table-column prop="receiver_school_label" label="接收者学校" min-width="11%">
        </el-table-column>
        <el-table-column prop="chat_pm_count" label="私信数量" min-width="10%">
        </el-table-column>
        <el-table-column prop="chat_create_time" label="发起时间" min-width="12%">
        </el-table-column>
      </el-table>
      <br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">私信记录</span>
      <el-table :data="chatPmData" style="font-size: 14px; width: 90%; color:black">
        <el-table-column prop="pm_id" label="私信编号" min-width="8%">
        </el-table-column>
        <el-table-column prop="sender_itsc" label="发送者" min-width="11%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.sender_itsc==senderItsc?'success':'danger'" @click="gotoUserInfoByTag(scope.row.sender_itsc, scope.row.sender_school_label)">{{ scope.row.sender_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sender_school_label" label="学校" min-width="13%">
        </el-table-column>
        <el-table-column prop="pm_msg" label="内容" min-width="56%">
        </el-table-column>
        <el-table-column prop="pm_create_time" label="时间" min-width="12%">
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page"
                     @prev-click="handlePrevClick" @next-click="handleNextClick"
                     small :page-size="10" layout="prev, next, jumper">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'ChatInfo',
    created() {
      this.senderItsc = ''
      this.updatePage()
    },
    watch: {
      "$route": "updatePage"
    },
    data() {
      return {
        chatInfoData: [],
        chatPmData: []
      }
    },
    methods: {
      updatePage(){
        this.chatId = this.$route.path.split('/').pop()
        this.page = 1
        this.chatListData = []
        this.chatPmData = []
        if (this.userItsc != ''){
          this.getChatInfo()
          this.getChatPm()
        }
      },
      getChatInfo(){
        const loading = this.$loading();
        request('/chat/chat_info', {chat_id: this.chatId}).then(
          (res) => {this.chatInfoData = res.chat_info; loading.close(); this.senderItsc = res.chat_info[0].sender_itsc;
          if (res.code != 200 && res.code != 801) this.$message(res.msg);})
      },
      getChatPm(){
        const loading = this.$loading();
        request('/chat/chat_pm', {chat_id: this.chatId, page: this.page-1}).then((res) => {this.chatPmData = res.chat_pm; loading.close()})
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      handleCurrentChange(pg){
        this.page = pg;
        this.getChatPm();
      },
      handlePrevClick(){
        this.page = this.page - 1;
        this.getChatPm();
      },
      handleNextClick(){
        this.page = this.page + 1;
        this.getChatPm();
      }
    }
  }
</script>