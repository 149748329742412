//import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import router from '../router'

export default async function request(url, body = {}, include_token = true){

    try{
      if(include_token){
        const token = localStorage.getItem('token');
        if(!token){
            if(!window.location.pathname.startsWith('/login')){
                router.push('/login?callback=' + window.location.pathname)
            }
            return false
        }
        Object.assign(body, {token: token});
      }
        //Object.assign(body, {token: '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'});
      const {data:res} = await axios.post(url + '.php', qs.stringify(body));
      if(res.code == 801){
          if(!window.location.pathname.startsWith('/login')){
              router.push('/login')
          }
          return false
      }
        //if(!res || !res.code){
            //this.$message("no result!");
            // Vue.$modal.show({
            //     title: 'Modal.error',
            //     content: 'Request.error',
            //     show_cancel: false,
            // });
        //}
        //if(res.code == 200){
            //this.$message("操作成功！页面略有延迟");
            //  if(!window.location.pathname.startsWith('/login')){
            //      router.push('/login?callback=' + window.location.pathname)
            //  }
            //  return false
        //}
        // if(res.code == 902){
        //     Vue.$modal.show({
        //         title: i18n.t('Modal.error'),
        //         content: res.msg,
        //         show_cancel: false,
        //     });
        //     return false
        // }
        // if(res.code >= 800){
        //     Vue.$modal.show({
        //         title: i18n.t('Modal.error'),
        //         content: i18n.t('Request.' + res.code),
        //         show_cancel: false,
        //     });
        //     return false
        // }
        // if(res.code >= 400 && !exclude_code.includes(res.code)){
        //     Vue.$modal.show({
        //         title: i18n.t('Modal.error'),
        //         content: i18n.t(url + '.' + res.code),
        //         show_cancel: false,
        //     });
        //     return false
        // }
    
        return res
    }catch(e){
        // console.log(e)
        return false
    }

    
}