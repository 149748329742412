<template>
  <el-container>
    <el-main>
      <span style="text-align: left; font-size: 16px; font-weight: bold;">树洞详情</span>
      <br><br>
      <el-input size="small" placeholder="搜索树洞号" v-model="searchId" class="input-with-select" 
                style="width:100%" @change="gotoPostInfoBySearch">
        <el-select v-model="searchIdType" slot="prepend" placeholder="选择编号类型" style="width: 130px">
          <el-option label="本校编号" value="1" style="color:black"></el-option>
          <el-option label="UNI编号" value="2" style="color:black"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="gotoPostInfoBySearch"></el-button>
      </el-input>
      <br><br>
      <el-table :data="postInfoData" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="post_id" label="本校编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="uni_post_id" label="UNI编号" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_topic" label="话题" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_msg" label="内容" min-width="28%">
        </el-table-column>
        <el-table-column prop="user_itsc" label="洞主" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.user_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.user_itsc, scope.row.user_school_label)">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="post_view" label="浏览" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_comment_num" label="评论" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_follower_num" label="围观" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_create_time" label="时间" min-width="9%">
        </el-table-column>
        <el-table-column prop="post_public" label="状态" min-width="4%">
        </el-table-column>
        <el-table-column prop="post_opt" label="操作" min-width="18%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDeletePost(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="warning" @click="handleShadowbanPost(scope.row)" plain>屏蔽</el-button>
            <el-button size="medium" type="success" @click="handlePublicPost(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <span style="text-align: left; font-size: 15px; font-weight: bold;">评论</span>
      <el-table :data="postCommentData" style="font-size: 14px; width: 100%; color:black">
        <el-table-column prop="comment_id" label="评论编号" min-width="7%">
        </el-table-column>
        <el-table-column prop="comment_order" label="楼层" min-width="5%">
        </el-table-column>
        <el-table-column prop="user_itsc" label="层主" min-width="9%">
          <template slot-scope="scope">
            <el-tag size="medium" :type="scope.row.user_is_real_name=='0'?'':'success'" @click="gotoUserInfoByTag(scope.row.user_itsc, scope.row.user_school_label)">{{ scope.row.user_itsc }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_school_label" label="学校" min-width="5%">
        </el-table-column>
        <el-table-column prop="comment_msg" label="内容" min-width="36%">
        </el-table-column>
        <el-table-column prop="comment_create_time" label="时间" min-width="12%">
        </el-table-column>
        <el-table-column prop="comment_public" label="状态" min-width="5%">
        </el-table-column>
        <el-table-column prop="comment_opt" label="操作" min-width="21%">
          <template slot-scope="scope">
            <el-button size="medium" type="danger" @click="handleDeleteComment(scope.row)" plain>删除</el-button>
            <el-button size="medium" type="warning" @click="handleShadowbanComment(scope.row)" plain>屏蔽</el-button>
            <el-button size="medium" type="success" @click="handlePublicComment(scope.row)" plain>公开</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<style>
  .el-table .cell {
    white-space: pre-line !important;
  }
</style>

<script>
  import request from '../utils/request.js'
  export default {
    name: 'PostInfo',
    created() {
      this.updatePage()
    },
    watch: {
      "$route": "updatePage"
    },
    data() {
      return {
        searchId: '',
        searchIdType: '',
        postInfoData:[],
        postCommentData: []
      }
    },
    methods:{
      updatePage(){
        if (this.$route.path.split('/').pop().startsWith("uni")){
          this.postId = this.$route.path.split('/').pop().substring(3)
          this.postIdIsUni = 1
        }
        else{
          this.postId = this.$route.path.split('/').pop()
          this.postIdIsUni = 2
        }
        this.searchId = ''
        this.searchIdType = ''
        this.postInfoData = []
        this.postCommentData = []
        this.getPostInfo()
      },
      getPostInfo(){
        const loading = this.$loading();
        request('/post/post_info',{post_id: this.postId, post_id_is_uni: this.postIdIsUni}).then(
          (res) => {this.postInfoData = res.post_info; this.postCommentData = res.comment_list;
                    loading.close(); if (res.code != 200 && res.code != 801) this.$message(res.msg);})
      },
      getCommentList(){
        const loading = this.$loading();
        request('/post/post_info',{post_id: this.postId, post_id_is_uni: this.postIdIsUni}).then(
          (res) => {this.postCommentData = res.comment_list; loading.close()})
      },
      gotoPostInfoBySearch(){
        if (this.searchIdType == ''){
          this.$message('请选择编号类型')
        }
        else if (this.searchId == ''){
          this.$message('请输入树洞编号')
        }
        if (this.searchIdType == 1){
          if (this.searchId != this.postId) window.open('/post/'+ this.searchId)
        }
        else if (this.searchIdType == 2){
          if (this.searchId != this.postId) window.open('/post/uni' + this.searchId)
        }
      },
      gotoUserInfoByTag(userItsc, userSchoolLabel){
        userSchoolLabel = userSchoolLabel.toLowerCase()
        if (userItsc == '洞主')
          window.open('/user/'+userSchoolLabel+'_'+this.postInfoData[0].user_itsc)
        else
          window.open('/user/'+userSchoolLabel+'_'+userItsc)
      },
      handleDeletePost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getPostInfo();})
      },
      handleShadowbanPost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'shadowban'}).then(
          (res) => {this.$message(res.msg); this.getPostInfo();})
      },
      handlePublicPost(row){
        request('/post/post_publicity',{post_id: row.uni_post_id, post_id_is_uni: 1, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getPostInfo();})
      },
      handleDeleteComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: this.postInfoData[0].uni_post_id, opt: 'delete'}).then(
          (res) => {this.$message(res.msg); this.getCommentList();})
      },
      handleShadowbanComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: this.postInfoData[0].uni_post_id, opt: 'shadowban'}).then(
          (res) => {this.$message(res.msg); this.getCommentList();})
      },
      handlePublicComment(row){
        request('/comment/comment_publicity',{comment_id: row.comment_id, uni_post_id: this.postInfoData[0].uni_post_id, opt: 'public'}).then(
          (res) => {this.$message(res.msg); this.getCommentList();})
      }
    }
  }
</script>